import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../public/assets/icon/icon.png'
import _imports_1 from '../../public/assets/icon/playstore1.png'
import _imports_2 from '../../public/assets/icon/appstore1.png'


const _hoisted_1 = { class: "container-calculations-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { syle: "height:30px" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    class: "home-icon",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToHome())),
                    icon: _ctx.home
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        style: {"overflow-y":"hidden"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "logo",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectToHome()))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)
          ])),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"width":"100%","text-align":"center"} }, [
                    _createTextVNode(" Lista e kalkulimeve (Paga "),
                    _createElementVNode("b", null, "Neto"),
                    _createTextVNode(" ne "),
                    _createElementVNode("b", null, "Bruto"),
                    _createTextVNode("): ")
                  ], -1)),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    class: "search-field-container"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                        variant: "outlined",
                        label: "Kerko",
                        type: "search",
                        "hide-details": "",
                        class: "search-field",
                        density: "compact",
                        "append-inner-icon": "search",
                        "max-width": "300"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_data_table, {
              "items-per-page": -1,
              items: _ctx.netToGrossTable,
              class: "calculations-table",
              headers: _ctx.headers,
              style: { width: '100%' },
              "hide-default-footer": "",
              search: _ctx.search,
              density: "compact",
              "fixed-header": "",
              height: "400"
            }, {
              [`item.net_wage`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.net_wage)), 1)
              ]),
              [`item.gross_wage`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.gross_wage)), 1)
              ]),
              [`item.employee_social_security_contribution`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.employee_social_security_contribution)), 1)
              ]),
              [`item.employee_health_insurance_contribution`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.employee_health_insurance_contribution)), 1)
              ]),
              [`item.employer_social_security_contribution`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.employer_social_security_contribution)), 1)
              ]),
              [`item.employer_health_insurance_contribution`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.employer_health_insurance_contribution)), 1)
              ]),
              [`item.income_tax`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.income_tax)), 1)
              ]),
              [`item.total_payment`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.total_payment)), 1)
              ]),
              [`item.company_expenses`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatNumber(item.company_expenses)), 1)
              ]),
              _: 2
            }, 1032, ["items", "headers", "search"])
          ]),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _cache[9] || (_cache[9] = _createElementVNode("span", { style: {"display":"flex","justify-content":"center","offset":"4"} }, " Merr aplikacionin pagaime: ", -1)),
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    class: "ion-text-start",
                    size: "12",
                    "size-sm": "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_grid, { class: "md" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, {
                            class: "ion-align-items-center md",
                            id: "iconCol"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, {
                                size: "4",
                                class: "ion-text-center md",
                                "size-sm": "3",
                                "size-md": "3"
                              }, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createElementVNode("div", { class: "icon" }, [
                                    _createElementVNode("a", {
                                      href: "https://play.google.com/store/apps/details?id=net.pagaime",
                                      target: "_blank"
                                    }, [
                                      _createElementVNode("img", {
                                        src: _imports_1,
                                        alt: "",
                                        id: "icon"
                                      })
                                    ])
                                  ], -1)
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, {
                                size: "4",
                                class: "ion-text-center",
                                "size-sm": "3",
                                "size-md": "3"
                              }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createElementVNode("div", { class: "icon" }, [
                                    _createElementVNode("a", {
                                      href: "https://apps.apple.com/us/app/paga-ime-kalkulo-pagen/id1620365264",
                                      target: "_blank"
                                    }, [
                                      _createElementVNode("img", {
                                        src: _imports_2,
                                        alt: ""
                                      })
                                    ])
                                  ], -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _cache[8] || (_cache[8] = _createElementVNode("p", null, [
                        _createTextVNode(" Ky aplikacion u mundesua nga: "),
                        _createElementVNode("a", { href: "https://burimenjerezore.com.al/" }, "burimenjerezore.com.al")
                      ], -1)),
                      _createVNode(_component_ion_card_subtitle, null, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Elif Burime Njerëzore është eksperienca disa vjeçare e një ekipi të talentuar ekonomistësh, krah për krah me programues të zotë, derdhur mbi një program revolucionar. Një program tejet i thjeshtë, ekstremisht efikas, plotësisht përmbushës i nevojave të burimeve njerëzore. "),
                          _createElementVNode("br", null, null, -1),
                          _createElementVNode("br", null, null, -1),
                          _createElementVNode("b", null, "Elif Burime Njerëzore ofron:", -1),
                          _createElementVNode("br", null, null, -1),
                          _createTextVNode(" Plotësimi automatik i "),
                          _createElementVNode("a", { href: "https://www.youtube.com/watch?v=BRgctbmQmKA" }, "listëpagesave nga tatimet.", -1),
                          _createTextVNode(" Të dhënat e punonjësve, llogaritjet e pagave, listpagesat, urdhërpagesat e bankave, dokumentacioni i kërkuar nga inspektoriati i punës, lejet, raportet mjeksore madje dhe vërejtjet dhe shumë më tepër, që të gjitha të automatizuara vetëm një klik larg."),
                          _createElementVNode("br", null, null, -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, { style: {"background-color":"#2e76bc"} }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("div", {
            class: "ion-text-center",
            style: {"padding-bottom":"10px","padding-top":"10px"}
          }, [
            _createElementVNode("a", {
              style: {"text-decoration":"none","color":"white"},
              href: "https://burimenjerezore.com.al/",
              target: "_blank"
            }, "burimenjerezore.com.al | "),
            _createElementVNode("a", {
              style: {"text-decoration":"none","color":"white"},
              href: "tel:04 45 26000"
            }, "04 45 26000")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}