
import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  IonFooter,
  IonCardSubtitle,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { home } from 'ionicons/icons';
export default defineComponent({
  name: "GrossToNet",
  props: {
    timeout: { type: Number, default: 1000 },
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonGrid,
    IonFooter,
    IonCardSubtitle,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {
      search: "",
      home,
      grossToNetTable: [
        {
          id: 1,
          net_wage: 35520,
          gross_wage: 40000,
          employee_social_security_contribution: 3800,
          employee_health_insurance_contribution: 680,
          employer_social_security_contribution: 6000,
          employer_health_insurance_contribution: 680,
          income_tax: 0,
          total_payment: 11160,
          payment_ratio: "31%",
          company_expenses: 6680,
          expense_ratio: 0.167,
        },
        {
          id: 2,
          net_wage: 39960,
          gross_wage: 45000,
          employee_social_security_contribution: 4275,
          employee_health_insurance_contribution: 765,
          employer_social_security_contribution: 6750,
          employer_health_insurance_contribution: 765,
          income_tax: 0,
          total_payment: 12555,
          payment_ratio: "31%",
          company_expenses: 7515,
          expense_ratio: 0.167,
        },
        {
          id: 3,
          net_wage: 44400,
          gross_wage: 50000,
          employee_social_security_contribution: 4750,
          employee_health_insurance_contribution: 850,
          employer_social_security_contribution: 7500,
          employer_health_insurance_contribution: 850,
          income_tax: 0,
          total_payment: 13950,
          payment_ratio: "31%",
          company_expenses: 8350,
          expense_ratio: 0.167,
        },
        {
          id: 4,
          net_wage: 46240,
          gross_wage: 55000,
          employee_social_security_contribution: 5225,
          employee_health_insurance_contribution: 935,
          employer_social_security_contribution: 8250,
          employer_health_insurance_contribution: 935,
          income_tax: 2600,
          total_payment: 17945,
          payment_ratio: "39%",
          company_expenses: 9185,
          expense_ratio: 0.167,
        },
        {
          id: 5,
          net_wage: 50030,
          gross_wage: 60000,
          employee_social_security_contribution: 5700,
          employee_health_insurance_contribution: 1020,
          employer_social_security_contribution: 9000,
          employer_health_insurance_contribution: 1020,
          income_tax: 3250,
          total_payment: 19990,
          payment_ratio: "40%",
          company_expenses: 10020,
          expense_ratio: 0.167,
        },
        {
          id: 6,
          net_wage: 53170,
          gross_wage: 65000,
          employee_social_security_contribution: 6175,
          employee_health_insurance_contribution: 1105,
          employer_social_security_contribution: 9750,
          employer_health_insurance_contribution: 1105,
          income_tax: 4550,
          total_payment: 22685,
          payment_ratio: "43%",
          company_expenses: 10855,
          expense_ratio: 0.167,
        },
        {
          id: 7,
          net_wage: 56960,
          gross_wage: 70000,
          employee_social_security_contribution: 6650,
          employee_health_insurance_contribution: 1190,
          employer_social_security_contribution: 10500,
          employer_health_insurance_contribution: 1190,
          income_tax: 5200,
          total_payment: 24730,
          payment_ratio: "43%",
          company_expenses: 11690,
          expense_ratio: 0.167,
        },
        {
          id: 8,
          net_wage: 60750,
          gross_wage: 75000,
          employee_social_security_contribution: 7125,
          employee_health_insurance_contribution: 1275,
          employer_social_security_contribution: 11250,
          employer_health_insurance_contribution: 1275,
          income_tax: 5850,
          total_payment: 26775,
          payment_ratio: "44%",
          company_expenses: 12525,
          expense_ratio: 0.167,
        },
        {
          id: 9,
          net_wage: 64540,
          gross_wage: 80000,
          employee_social_security_contribution: 7600,
          employee_health_insurance_contribution: 1360,
          employer_social_security_contribution: 12000,
          employer_health_insurance_contribution: 1360,
          income_tax: 6500,
          total_payment: 28820,
          payment_ratio: "45%",
          company_expenses: 13360,
          expense_ratio: 0.167,
        },
        {
          id: 10,
          net_wage: 68330,
          gross_wage: 85000,
          employee_social_security_contribution: 8075,
          employee_health_insurance_contribution: 1445,
          employer_social_security_contribution: 12750,
          employer_health_insurance_contribution: 1445,
          income_tax: 7150,
          total_payment: 30865,
          payment_ratio: "45%",
          company_expenses: 14195,
          expense_ratio: 0.167,
        },
        {
          id: 11,
          net_wage: 72120,
          gross_wage: 90000,
          employee_social_security_contribution: 8550,
          employee_health_insurance_contribution: 1530,
          employer_social_security_contribution: 13500,
          employer_health_insurance_contribution: 1530,
          income_tax: 7800,
          total_payment: 32910,
          payment_ratio: "46%",
          company_expenses: 15030,
          expense_ratio: 0.167,
        },
        {
          id: 12,
          net_wage: 75910,
          gross_wage: 95000,
          employee_social_security_contribution: 9025,
          employee_health_insurance_contribution: 1615,
          employer_social_security_contribution: 14250,
          employer_health_insurance_contribution: 1615,
          income_tax: 8450,
          total_payment: 34955,
          payment_ratio: "46%",
          company_expenses: 15865,
          expense_ratio: 0.167,
        },
        {
          id: 13,
          net_wage: 79700,
          gross_wage: 100000,
          employee_social_security_contribution: 9500,
          employee_health_insurance_contribution: 1700,
          employer_social_security_contribution: 15000,
          employer_health_insurance_contribution: 1700,
          income_tax: 9100,
          total_payment: 37000,
          payment_ratio: "46%",
          company_expenses: 16700,
          expense_ratio: 0.167,
        },
        {
          id: 14,
          net_wage: 83490,
          gross_wage: 105000,
          employee_social_security_contribution: 9975,
          employee_health_insurance_contribution: 1785,
          employer_social_security_contribution: 15750,
          employer_health_insurance_contribution: 1785,
          income_tax: 9750,
          total_payment: 39045,
          payment_ratio: "47%",
          company_expenses: 17535,
          expense_ratio: 0.167,
        },
        {
          id: 15,
          net_wage: 87280,
          gross_wage: 110000,
          employee_social_security_contribution: 10450,
          employee_health_insurance_contribution: 1870,
          employer_social_security_contribution: 16500,
          employer_health_insurance_contribution: 1870,
          income_tax: 10400,
          total_payment: 41090,
          payment_ratio: "47%",
          company_expenses: 18370,
          expense_ratio: 0.167,
        },
        {
          id: 16,
          net_wage: 91070,
          gross_wage: 115000,
          employee_social_security_contribution: 10925,
          employee_health_insurance_contribution: 1955,
          employer_social_security_contribution: 17250,
          employer_health_insurance_contribution: 1955,
          income_tax: 11050,
          total_payment: 43135,
          payment_ratio: "47%",
          company_expenses: 19205,
          expense_ratio: 0.167,
        },
        {
          id: 17,
          net_wage: 94860,
          gross_wage: 120000,
          employee_social_security_contribution: 11400,
          employee_health_insurance_contribution: 2040,
          employer_social_security_contribution: 18000,
          employer_health_insurance_contribution: 2040,
          income_tax: 11700,
          total_payment: 45180,
          payment_ratio: "48%",
          company_expenses: 20040,
          expense_ratio: 0.167,
        },
        {
          id: 18,
          net_wage: 98650,
          gross_wage: 125000,
          employee_social_security_contribution: 11875,
          employee_health_insurance_contribution: 2125,
          employer_social_security_contribution: 18750,
          employer_health_insurance_contribution: 2125,
          income_tax: 12350,
          total_payment: 47225,
          payment_ratio: "48%",
          company_expenses: 20875,
          expense_ratio: 0.167,
        },
        {
          id: 19,
          net_wage: 102440,
          gross_wage: 130000,
          employee_social_security_contribution: 12350,
          employee_health_insurance_contribution: 2210,
          employer_social_security_contribution: 19500,
          employer_health_insurance_contribution: 2210,
          income_tax: 13000,
          total_payment: 49270,
          payment_ratio: "48%",
          company_expenses: 21710,
          expense_ratio: 0.167,
        },
        {
          id: 20,
          net_wage: 106230,
          gross_wage: 135000,
          employee_social_security_contribution: 12825,
          employee_health_insurance_contribution: 2295,
          employer_social_security_contribution: 20250,
          employer_health_insurance_contribution: 2295,
          income_tax: 13650,
          total_payment: 51315,
          payment_ratio: "48%",
          company_expenses: 22545,
          expense_ratio: 0.167,
        },
        {
          id: 21,
          net_wage: 110020,
          gross_wage: 140000,
          employee_social_security_contribution: 13300,
          employee_health_insurance_contribution: 2380,
          employer_social_security_contribution: 21000,
          employer_health_insurance_contribution: 2380,
          income_tax: 14300,
          total_payment: 53360,
          payment_ratio: "49%",
          company_expenses: 23380,
          expense_ratio: 0.167,
        },
        {
          id: 22,
          net_wage: 113810,
          gross_wage: 145000,
          employee_social_security_contribution: 13775,
          employee_health_insurance_contribution: 2465,
          employer_social_security_contribution: 21750,
          employer_health_insurance_contribution: 2465,
          income_tax: 14950,
          total_payment: 55405,
          payment_ratio: "49%",
          company_expenses: 24215,
          expense_ratio: 0.167,
        },
        {
          id: 23,
          net_wage: 117600,
          gross_wage: 150000,
          employee_social_security_contribution: 14250,
          employee_health_insurance_contribution: 2550,
          employer_social_security_contribution: 22500,
          employer_health_insurance_contribution: 2550,
          income_tax: 15600,
          total_payment: 57450,
          payment_ratio: "49%",
          company_expenses: 25050,
          expense_ratio: 0.167,
        },
        {
          id: 24,
          net_wage: 121390,
          gross_wage: 155000,
          employee_social_security_contribution: 14725,
          employee_health_insurance_contribution: 2635,
          employer_social_security_contribution: 23250,
          employer_health_insurance_contribution: 2635,
          income_tax: 16250,
          total_payment: 59495,
          payment_ratio: "49%",
          company_expenses: 25885,
          expense_ratio: 0.167,
        },
        {
          id: 25,
          net_wage: 125180,
          gross_wage: 160000,
          employee_social_security_contribution: 15200,
          employee_health_insurance_contribution: 2720,
          employer_social_security_contribution: 24000,
          employer_health_insurance_contribution: 2720,
          income_tax: 16900,
          total_payment: 61540,
          payment_ratio: "49%",
          company_expenses: 26720,
          expense_ratio: 0.167,
        },
        {
          id: 26,
          net_wage: 128970,
          gross_wage: 165000,
          employee_social_security_contribution: 15675,
          employee_health_insurance_contribution: 2805,
          employer_social_security_contribution: 24750,
          employer_health_insurance_contribution: 2805,
          income_tax: 17550,
          total_payment: 63585,
          payment_ratio: "49%",
          company_expenses: 27555,
          expense_ratio: 0.167,
        },
        {
          id: 27,
          net_wage: 132760,
          gross_wage: 170000,
          employee_social_security_contribution: 16150,
          employee_health_insurance_contribution: 2890,
          employer_social_security_contribution: 25500,
          employer_health_insurance_contribution: 2890,
          income_tax: 18200,
          total_payment: 65630,
          payment_ratio: "49%",
          company_expenses: 28390,
          expense_ratio: 0.167,
        },
        {
          id: 28,
          net_wage: 136550,
          gross_wage: 175000,
          employee_social_security_contribution: 16625,
          employee_health_insurance_contribution: 2975,
          employer_social_security_contribution: 26250,
          employer_health_insurance_contribution: 2975,
          income_tax: 18850,
          total_payment: 67675,
          payment_ratio: "50%",
          company_expenses: 29225,
          expense_ratio: 0.167,
        },
        {
          id: 29,
          net_wage: 140680,
          gross_wage: 180000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3060,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3060,
          income_tax: 19500,
          total_payment: 68842,
          payment_ratio: "49%",
          company_expenses: 29522,
          expense_ratio: 0.164,
        },
        {
          id: 30,
          net_wage: 144945,
          gross_wage: 185000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3145,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3145,
          income_tax: 20150,
          total_payment: 69662,
          payment_ratio: "48%",
          company_expenses: 29607,
          expense_ratio: 0.16,
        },
        {
          id: 31,
          net_wage: 149210,
          gross_wage: 190000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3230,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3230,
          income_tax: 20800,
          total_payment: 70482,
          payment_ratio: "47%",
          company_expenses: 29692,
          expense_ratio: 0.156,
        },
        {
          id: 32,
          net_wage: 153475,
          gross_wage: 195000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3315,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3315,
          income_tax: 21450,
          total_payment: 71302,
          payment_ratio: "46%",
          company_expenses: 29777,
          expense_ratio: 0.153,
        },
        {
          id: 33,
          net_wage: 157740,
          gross_wage: 200000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3400,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3400,
          income_tax: 22100,
          total_payment: 72122,
          payment_ratio: "46%",
          company_expenses: 29862,
          expense_ratio: 0.149,
        },
        {
          id: 34,
          net_wage: 161505,
          gross_wage: 205000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3485,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3485,
          income_tax: 23250,
          total_payment: 73442,
          payment_ratio: "45%",
          company_expenses: 29947,
          expense_ratio: 0.146,
        },
        {
          id: 35,
          net_wage: 165270,
          gross_wage: 210000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3570,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3570,
          income_tax: 24400,
          total_payment: 74762,
          payment_ratio: "45%",
          company_expenses: 30032,
          expense_ratio: 0.143,
        },
        {
          id: 36,
          net_wage: 169035,
          gross_wage: 215000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3655,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3655,
          income_tax: 25550,
          total_payment: 76082,
          payment_ratio: "45%",
          company_expenses: 30117,
          expense_ratio: 0.14,
        },
        {
          id: 37,
          net_wage: 172800,
          gross_wage: 220000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3740,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3740,
          income_tax: 26700,
          total_payment: 77402,
          payment_ratio: "45%",
          company_expenses: 30202,
          expense_ratio: 0.137,
        },
        {
          id: 38,
          net_wage: 176565,
          gross_wage: 225000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3825,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3825,
          income_tax: 27850,
          total_payment: 78722,
          payment_ratio: "45%",
          company_expenses: 30287,
          expense_ratio: 0.135,
        },
        {
          id: 39,
          net_wage: 180330,
          gross_wage: 230000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3910,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3910,
          income_tax: 29000,
          total_payment: 80042,
          payment_ratio: "44%",
          company_expenses: 30372,
          expense_ratio: 0.132,
        },
        {
          id: 40,
          net_wage: 184095,
          gross_wage: 235000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3995,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3995,
          income_tax: 30150,
          total_payment: 81362,
          payment_ratio: "44%",
          company_expenses: 30457,
          expense_ratio: 0.13,
        },
        {
          id: 41,
          net_wage: 187860,
          gross_wage: 240000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4080,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4080,
          income_tax: 31300,
          total_payment: 82682,
          payment_ratio: "44%",
          company_expenses: 30542,
          expense_ratio: 0.127,
        },
        {
          id: 42,
          net_wage: 191625,
          gross_wage: 245000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4165,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4165,
          income_tax: 32450,
          total_payment: 84002,
          payment_ratio: "44%",
          company_expenses: 30627,
          expense_ratio: 0.125,
        },
        {
          id: 43,
          net_wage: 195390,
          gross_wage: 250000,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4250,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4250,
          income_tax: 33600,
          total_payment: 85322,
          payment_ratio: "44%",
          company_expenses: 30712,
          expense_ratio: 0.123,
        },
      ],
      headers: [
        { title: "Paga Bruto", value: "gross_wage" },
        { title: "Totali", value: "total_payment" },
        { title: "Total / P.Neto", value: "payment_ratio" },
        { title: "Paga Neto", value: "net_wage" },
        {
          title: "Sig.Shoqerore(Punonjes)",
          value: "employee_social_security_contribution",
        },
        {
          title: "Sig.Shendetsore(Punonjes)",
          value: "employee_health_insurance_contribution",
        },
        {
          title: "Sig.Shoqerore(Punedhenes)",
          value: "employer_social_security_contribution",
        },
        {
          title: "Sig.Shendetesore(Punedhenesi)",
          value: "employer_health_insurance_contribution",
        },
        { title: "TAP", value: "income_tax" },

        { title: "Shpenzimet e Kompanise", value: "company_expenses" },
        { title: "Shpenzime / P.Bruto", value: "expense_ratio" },
      ],
    };
  },

  methods: {
    redirectToHome() {
      this.$router.push({ name: "Home" })
    },
    isOnlyNumberAllowed(evt: any) {
      evt = evt || window.event;

      let charCode = evt.which || evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) && // Not a number
        charCode !== 46 && // Not a period (.)
        charCode !== 44 // Not a comma (,)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatNumber(value: any) {
      //function for changing the format of no only with 2 no after the comma.
      if (value == null) {
        return 0;
      } else {
        let num = parseFloat(value);
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
  },
});
